import React, { useState, useRef } from 'react';

const CurrencyInput = ({ value, onChange, className }) => {
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(0);

  const formatValue = (val) => {
    // Remove all non-digits
    const numbers = val.replace(/\D/g, '');
    
    // Convert to cents then to reais
    const amount = (parseInt(numbers, 10) / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return amount;
  };

  const handleChange = (e) => {
    const { value: inputValue, selectionStart } = e.target;
    const oldLength = inputValue.length;
    
    // Remove currency symbol and separators
    const cleanValue = inputValue.replace(/\D/g, '');
    const numericValue = parseInt(cleanValue, 10) / 100;
    
    // Store cursor position before formatting
    if (selectionStart) {
      setCursorPosition(selectionStart);
    }

    // Call parent's onChange with numeric value
    onChange(numericValue);

    // Schedule cursor position restoration
    requestAnimationFrame(() => {
      if (inputRef.current) {
        const newCursorPosition = Math.max(
          0,
          cursorPosition + (inputRef.current.value.length - oldLength)
        );
        inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    });
  };

  const handleKeyDown = (e) => {
    // Allow navigation keys
    if (['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'].includes(e.key)) {
      return;
    }

    // Only allow numbers
    if (!/^\d$/.test(e.key) && !e.ctrlKey && !e.metaKey) {
      e.preventDefault();
    }
  };

  return (
    <input
      ref={inputRef}
      type="text"
      inputMode="numeric"
      value={formatValue(String(Math.abs(value * 100 || 0)))}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      className={className}
      placeholder="R$ 0,00"
    />
  );
};

export default CurrencyInput;