import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { CreditCard, DollarSign, Calendar, Clock, ChevronDown, ChevronUp } from 'lucide-react';
import { formatCurrency } from '../utils/formatters';
import { fetchCreditCards, fetchCreditCardTransactions } from '../api/financialApi';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';
import { format, parseISO, setDate, addMonths, subMonths, isAfter, isBefore, startOfDay, endOfDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { getCategoryIcon } from '../utils/dashboardHelpers';


const InfoItem = ({ icon: Icon, label, value }) => (
  <div className="bg-black/20 p-4 rounded-lg">
    <p className="text-xs text-white/70 flex items-center mb-1">
      <Icon className="w-3 h-3 mr-1" /> {label}
    </p>
    <p className="text-base font-bold text-white">{value}</p>
  </div>
);

const getStatementPeriod = (closingDay) => {
  const today = startOfDay(new Date());
  let closingDate = setDate(today, closingDay);

  // If today is after closing date, get next month's closing date
  if (isAfter(today, closingDate)) {
    closingDate = addMonths(closingDate, 1);
  }

  // Get the start of the current statement period (previous closing date)
  const periodStart = endOfDay(subMonths(closingDate, 1));
  const periodEnd = endOfDay(closingDate);

  return {
    periodStart,
    periodEnd,
    closingDate
  };
};

const isTransactionInPeriod = (transactionDate, startDate, endDate) => {
  const date = startOfDay(parseISO(transactionDate));
  return isAfter(date, startDate) && isBefore(date, endDate);
};

const CardItem = React.memo(({ card, isExpanded, onToggle, transactions }) => {
  const cardStyle = useMemo(() => ({
    background: `linear-gradient(135deg, 
      hsl(${Math.abs(card.card_name.split('').reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0)) % 360}, 50%, 35%), 
      hsl(${Math.abs(card.card_name.split('').reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0)) % 360}, 50%, 20%))`
  }), [card.card_name]);

  const statementPeriod = useMemo(() => 
    getStatementPeriod(card.validity_period_days),
    [card.validity_period_days]
  );

  const currentStatementTransactions = useMemo(() => 
    transactions.filter(t => 
      isTransactionInPeriod(t.purchase_date, statementPeriod.periodStart, statementPeriod.periodEnd)
    ),
    [transactions, statementPeriod]
  );

  const currentStatementTotal = useMemo(() => 
    currentStatementTransactions.reduce((sum, t) => sum + t.amount, 0),
    [currentStatementTransactions]
  );

  const dueDate = useMemo(() => {
    if (!card.invoice_date) return null;
    
    let nextDueDate = parseISO(card.invoice_date);
    
    if (isAfter(new Date(), nextDueDate)) {
      nextDueDate = addMonths(nextDueDate, 1);
    }
    
    return nextDueDate;
  }, [card.invoice_date]);

  return (
    <div className="rounded-xl overflow-hidden shadow-lg">
      <div style={cardStyle}>
        <button
          onClick={onToggle}
          className="w-full p-4 flex items-center justify-between bg-black/20 text-white hover:bg-black/30 transition-colors"
        >
          <div className="flex items-center space-x-4">
            <CreditCard className="w-6 h-6 text-white" />
            <span className="text-white font-medium">{card.card_name}</span>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-white font-medium">
              {formatCurrency(currentStatementTotal)}
            </span>
            {isExpanded ? (
              <ChevronUp className="w-5 h-5 text-white/80" />
            ) : (
              <ChevronDown className="w-5 h-5 text-white/80" />
            )}
          </div>
        </button>

        {isExpanded && (
          <div className="border-t border-white/10">
            <div className="p-4 bg-black/20 grid grid-cols-1 sm:grid-cols-2 gap-4">
              <InfoItem
                icon={DollarSign}
                label="Limite Total"
                value={formatCurrency(card.limit || 0)}
              />
              <InfoItem
                icon={DollarSign}
                label="Limite Disponível"
                value={formatCurrency(Math.max(0, (card.limit || 0) - currentStatementTotal))}
              />
              <InfoItem
                icon={Calendar}
                label="Vencimento"
                value={dueDate ? format(dueDate, "'Dia' dd 'de' MMMM", { locale: ptBR }) : 'Não definido'}
              />
              <InfoItem
                icon={Clock}
                label="Fechamento"
                value={format(statementPeriod.closingDate, "'Dia' dd 'de' MMMM", { locale: ptBR })}
              />
            </div>
            
            <div className="p-4 bg-black/10">
              <h4 className="text-white/80 text-sm mb-2">
                Período: {format(statementPeriod.periodStart, "dd/MM/yyyy")} até {format(statementPeriod.periodEnd, "dd/MM/yyyy")}
              </h4>
            </div>

            <div className="max-h-96 overflow-y-auto">
              {currentStatementTransactions.length > 0 ? (
                currentStatementTransactions
                  .sort((a, b) => parseISO(b.purchase_date).getTime() - parseISO(a.purchase_date).getTime())
                  .map((transaction, index) => (
                    <div key={index} className="p-4 hover:bg-black/30 transition-colors">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="w-10 h-10 bg-white/10 rounded-full flex items-center justify-center">
                            {getCategoryIcon(transaction.category)}
                          </div>
                          <div>
                            <p className="text-white font-medium">{transaction.description || 'Sem descrição'}</p>
                            <p className="text-sm text-white/70">{transaction.category || 'Sem categoria'}</p>
                          </div>
                        </div>
                        <div className="text-right">
                          <p className="text-white font-medium">{formatCurrency(transaction.amount)}</p>
                          <p className="text-sm text-white/70">
                            {format(parseISO(transaction.purchase_date), "dd 'de' MMM", { locale: ptBR })}
                          </p>
                          {transaction.installment > 1 && (
                            <p className="text-xs text-white/70">
                              {transaction.currentInstallment || 1}/{transaction.installment}x
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="p-4 text-center text-white/70">
                  Nenhuma transação na fatura atual
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

const Cartoes = () => {
  const [cards, setCards] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedCard, setExpandedCard] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [cardsData, transactionsData] = await Promise.all([
          fetchCreditCards(),
          fetchCreditCardTransactions()
        ]);
        
        setCards(cardsData);
        setTransactions(transactionsData);
      } catch (err) {
        console.error('Error loading credit card data:', err);
        setError('Falha ao carregar dados dos cartões. Tente novamente mais tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  if (isLoading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  const totalLimit = cards.reduce((sum, card) => sum + (parseFloat(card.limit) || 0), 0);

  return (
    <div className="h-full overflow-y-auto bg-gray-900">
      <div className="p-6 space-y-6">
        <h2 className="text-2xl font-bold text-white">Cartões de Crédito</h2>
        
        <div className="bg-green-600 p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-white mb-2">
            Limite Total Disponível
          </h3>
          <p className="text-3xl font-bold text-white">
            {formatCurrency(totalLimit)}
          </p>
        </div>

        <div className="space-y-4">
          {cards.map((card) => (
            <CardItem
              key={card.card_name}
              card={card}
              isExpanded={expandedCard === card.card_name}
              onToggle={() => setExpandedCard(
                expandedCard === card.card_name ? null : card.card_name
              )}
              transactions={transactions.filter(t => t.card_name === card.card_name)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cartoes;